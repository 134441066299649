import { AxiosResponse }   from 'axios';
import { useMemo, useRef } from 'react';
import { useTranslation }  from 'react-i18next';
import useSWR              from 'swr';

import { IAnalyticsMobileModel } from '@src/models';
import { callApi }               from '@utils/apiCaller';

import { StatisticsComponent } from '../components/StatisticsComponent';

const useAnalyticsMobile = (): [IAnalyticsMobileModel | null, boolean] => {
  const analytics = useRef<IAnalyticsMobileModel | null>(null);

  const { data, isValidating } = useSWR<AxiosResponse<IAnalyticsMobileModel>>('feature/AnalyticsMobile', callApi);

  if (data?.data) {
    analytics.current = data.data;
  }

  return [analytics.current, isValidating];
};

export const Statistics = () => {
  const { t } = useTranslation();

  const [analytics, isFetching] = useAnalyticsMobile();

  const statistics = useMemo(() => (analytics ? [
    { label: t('average'), today: 'session time', total: `~ ${Math.floor(analytics.evrSessionLenght / 60)}m` },
    { label: t('downloads'), today: analytics.usersCountToday, total: analytics.usersCountTotal },
    { label: t('opens'), today: analytics.sessionCountToday, total: analytics.sessionCountTotal },
  ] : []), [analytics]);

  return (
    <StatisticsComponent
      isFetching = {isFetching}
      statistics = {statistics}
    />
  );
};
