import { Fragment }       from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

interface IStatisticsComponent {
  isFetching : boolean;
  statistics : {
    label: string;
    today: number | string;
    total: number | string;
  }[];
}

export const StatisticsComponent = ({ isFetching, statistics }: IStatisticsComponent) => {
  const { t } = useTranslation();

  return (
    <StatisticsComponent.Wrapper>
      {isFetching && (
        <StatisticsComponent.LoaderWrapper>
          <LoadingOutlined />
        </StatisticsComponent.LoaderWrapper>
      )}
      <StatisticsComponent.Content opacity={isFetching ? 0.3 : 1}>
        <StatisticsComponent.Label>
          <p>Newsie App</p>
        </StatisticsComponent.Label>
        {statistics.map((stat) => (
          <Fragment key={stat.label}>
            <StatisticsComponent.Separator />
            <StatisticsComponent.Item>
              <p>{typeof stat.total === 'number' ? new Intl.NumberFormat().format(stat.total) : stat.total}</p>
              <div>
                <p>{stat.label}</p>
                <p>
                  {typeof stat.today === 'number' ? new Intl.NumberFormat().format(stat.today) : stat.today}
                  {typeof stat.today === 'number' ? ` ${t('today')}` : ''}
                </p>
              </div>
            </StatisticsComponent.Item>
          </Fragment>
        ))}
      </StatisticsComponent.Content>
    </StatisticsComponent.Wrapper>
  );
};

StatisticsComponent.Content = styled.div<{ opacity: number }>`
  align-items     : center;
  box-shadow      : 0 1px 4px #15223214;
  display         : flex;
  height          : 100%;
  justify-content : space-between;
  opacity         : ${({ opacity }) => opacity};
  padding         : 13px 25px;
  width           : 100%;
`;

StatisticsComponent.Item = styled.div`
  align-items : flex-end;
  display     : flex;
  gap         : 6px;

  p {
    color          : ${({ theme }) => theme.color.black};
    font-weight    : 600;
    text-transform : uppercase;
  }

  > p {
    font-size      : 24px;
    line-height    : 27px;
    text-transform : none;
  }

  > div { padding-bottom: 3px; }

  > div p:first-child {
    font-size   : 7px;
    line-height : 7px;
  }

  > div p:last-child {
    color       : ${({ theme }) => theme.color.green};
    font-size   : 9px;
    line-height : 10px;
  }
`;

StatisticsComponent.Label = styled.div`
  min-width: 95px;

  p { color: ${({ theme }) => theme.color.darkGray}; }
`;

StatisticsComponent.LoaderWrapper = styled.div`
  align-items     : center;
  color           : ${({ theme }) => theme.color.blue};
  display         : flex;
  font-size       : 25px;
  height          : 100%;
  justify-content : center;
  left            : 0;
  position        : absolute;
  top             : 0;
  width           : 100%;
`;

StatisticsComponent.Separator = styled.div`
  background-color : ${({ theme }) => theme.color.lightGray};
  height           : 100%;
  width            : 1px;
`;

StatisticsComponent.Wrapper = styled.div`
  background-color : #fff;
  border-radius    : 6px;
  height           : 67px;
  position         : relative;
  width            : 100%;
`;
